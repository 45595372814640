import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const SubWrapper = styled.div`
  max-width: 60%;
  margin: 0 auto;
  background: #009ee0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background: #ff5f56;
  p {
    margin-bottom: 0;
  }

  @media (max-width: 1400px) {
    max-width: 80%;
  }

  @media (max-width: 960px) {
    max-width: 90%;
    flex-direction: column;
  }

  @media (max-width: 680px) {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  @media (max-width: 960px) {
    text-align: center;
  }
`;

export const ContentWhite = styled.p`
   {
    color: white;
  }
`;
