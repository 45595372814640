import React from 'react';
import Layout from '../../components/common/Layout';
import Support from '../../components/support/Support';
import SEO from '../../components/SEO';
import GlobalNotice from '../../components/landing/GlobalNotice';

const SupportPage = () => (
  <Layout>
    <SEO
      title="Support - Nextcloud Hosting"
      description="Du hast Fragen, wir helfen gern."
    />
    <GlobalNotice lang="de" />
    <Support />
  </Layout>
);

export default SupportPage;
