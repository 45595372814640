import React from 'react';
import propTypes from 'prop-types';
import En from './En';
import De from './De';

const Globalnotice = ({ lang }) => (lang === 'en' ? <En /> : <De />);

Globalnotice.propTypes = {
  lang: propTypes.string,
};

export default Globalnotice;
