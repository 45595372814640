import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GlobalNotice from "../NoticeWrapper";

export default () => {
  const notice = useStaticQuery(graphql`
  query {
  notice: allPloneDocument(filter: {_path: {regex: "/de/"}, _id: {eq: "globalnotice"}}) {
    edges {
      node {
        id
        text {
          data
          react
        }
        description
      }
    }
  }
  }
  `);
  if (notice.notice.edges.length !== 0){
    return <GlobalNotice notice={notice.notice.edges[0].node} lang="de"/>;
  }
  return '';

};