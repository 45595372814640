import React from 'react';
import Container from 'components/common/Container';
import { Link } from 'gatsby';
import { Wrapper, SubWrapper, Content, ContentWhite } from './styles';

const GlobalNotice = ({ notice, lang }) => (
  <Link to={notice.description}>
    <Wrapper as={Container}>
      <SubWrapper>
        <Content>
          <ContentWhite>
            <p dangerouslySetInnerHTML={{ __html: notice.text.data }}></p>
          </ContentWhite>
        </Content>
      </SubWrapper>
    </Wrapper>
  </Link>
);

export default GlobalNotice;
